var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c("j-tree-table", {
        attrs: {
          url: _vm.url,
          topValue: "0",
          queryKey: "id",
          columns: _vm.columns,
          tableProps: _vm.tableProps,
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function (props) {
              return [
                _c("a", { on: { click: () => _vm.handleEdit(props.record) } }, [
                  _vm._v("编辑"),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }